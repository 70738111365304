import { PlayCircleOutlined, SendOutlined, StarOutlined } from '@ant-design/icons';
import { ThemeProvider } from '@mui/material';
import { Button, Col, DatePicker, Divider, Form, Modal, Radio, Row, Select, Spin, notification } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import * as Api from 'api';
import ImageEditTag from 'components/image/ImageEditTag';
import ImageFilterTag from 'components/image/ImageFilterTag';
import React, { useEffect, useState } from 'react';
import useApi from 'redux/api/api.hook';
import useAuth from 'redux/auth/auth.hook';
import outerTheme from 'theme/mui';
import classes from './style.module.scss';

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;
const ShareImageFilter = (props) => {
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const { md } = useBreakpoint();
  const { images, filterOptions, tags, setImages, setFilterOption, setTags, setTagAssigns } = useApi();
  const { auth: { profile } } = useAuth();
  const [loading, setLoading] = useState(false);
  const [cameraOptions, setCameraOptions] = useState([{ label: 'All Cameras', value: '0' }]);
  const [trashCounter, setTrashCounter] = useState(0);
  const [openHD, setOpenHD] = React.useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [categories, setCategories] = useState([]);
  const [editCategories, setEditCategories] = useState([]);
  const [selectedEditOptions, setSelectedEditOptions] = useState({
    able_category_change: false,
    able_delete: false,
    able_request_hd: false,
    able_tag: false,
  });

  useEffect(() => {
    if (profile) {
      http_load_categories();
    }
  }, [profile]);

  useEffect(() => {
    form.setFieldsValue({ filterOptions });
    form.setFieldsValue({ category: filterOptions.category });
    setSelectAll(false);
  }, [filterOptions]);

  useEffect(() => {
    if (props.sharedAccounts.length > 0) {
      const cameraList: any = [{ label: 'All Cameras', value: '0' }];
      props.sharedAccounts.forEach((account) => {
        const editOptions = {
          able_category_change: account.able_category_change == '1',
          able_delete: account.able_delete == '1',
          able_request_hd: account.able_request_hd == '1',
          able_tag: account.able_tag == '1',
        };
        // const sharedTags = account.tags.map((tag) => ({ tag }));
        const options = account.cameras.map((camera) => (
          { label: camera.name, value: camera.id, object: editOptions, tags: account.tags }
        ));
        cameraList.push({ label: account.email, options });
      });
      setCameraOptions(cameraList);
    }
  }, [props.sharedAccounts]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (trashCounter > 0) {
        setTrashCounter(trashCounter - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [trashCounter]);

  const onCameraSelect = (value, option) => {
    let editOption;
    if (value == '0') {
      editOption = {
        able_category_change: false,
        able_delete: false,
        able_request_hd: false,
        able_tag: false,
      };
    } else {
      editOption = option.object;
      setTags(option.tags);
    }
    setSelectedEditOptions(editOption);
    props.changeEditOptions(editOption);
    setFilterOption({ ...filterOptions, camera_id: value });
  };

  const onValuesChange = (values, allvalues) => {
    setFilterOption({ ...filterOptions, ...allvalues, user_id: profile.user_id });
  };

  const onChangeDate = (date: any, dateString: string[]) => {
    setFilterOption({ ...filterOptions, fromDate: dateString[0], toDate: dateString[1] });
  };

  const onClickSelectAll = () => {
    const selectedState = !selectAll;
    images.map((image) => {
      image.selected = selectedState;
      return image;
    });
    setImages(images);
    setSelectAll(selectedState);
  };

  const changeDeerSubCategroy = (e) => {
    const val = e.target.value;
    setFilterOption({ ...filterOptions, antlered: val });
  };

  const onChangeXlOnly = (e) => {
    setFilterOption({ ...filterOptions, xl_only: !filterOptions.xl_only, video_only: false });
  };

  const onChangeVideoOnly = (e) => {
    const val = e.target.value;
    setFilterOption({ ...filterOptions, video_only: !filterOptions.video_only, xl_only: false });
  };

  const onChangeFavoriteOnly = (e) => {
    const val = e.target.value;
    setFilterOption({ ...filterOptions, favorite_only: !filterOptions.favorite_only });
  };

  const onChangeCategory = (e) => {
    const ids: any = [];

    images.map((image) => {
      if (image.selected) {
        ids.push(image.id);
      }
      return null;
    });

    formEdit.resetFields();
    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
      return;
    }

    httpEdit(ids, 'category', e, () => {
      let shouldMove = false;
      if (filterOptions.category == 'All' || filterOptions.category == undefined) {
        shouldMove = false;
      } else if ((filterOptions.category == 'Deer' && filterOptions.antlered == 0)) {
        if (e.includes('Deer')) {
          shouldMove = false;
        } else {
          shouldMove = true;
        }
      } else {
        shouldMove = true;
      }
      let newImages = [...images];
      if (shouldMove) {
        newImages = newImages.filter((item) => !item.selected);
      } else if (e.includes('Deer')) {
        const arr = e.replace(/[)(]/g, '').split(' ');
        const cate = arr[0];
        const cate_sub = arr[1];
        newImages.map((item) => {
          if (item.selected) {
            item.category = cate;
            item.category_sub = cate_sub;
            item.selected = false;
          }
          return item;
        });
      } else {
        newImages.map((item) => {
          if (item.selected) {
            item.category = e;
            item.selected = false;
          }
          return item;
        });
      }
      setImages(newImages);
      notification.open({
        message: 'Your Change was Successful',
      });
    });
  };

  const onChangeTag = (tag) => {
    const ids: any = [];

    images.map((image) => {
      if (image.selected) {
        ids.push(image.id);
      }
      return null;
    });

    formEdit.resetFields();
    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
      return;
    }

    httpEdit(ids, 'tag', tag, () => {
      let shouldMove = true;
      if (filterOptions.tag == '' || filterOptions.tag == undefined) {
        shouldMove = false;
      }
      let newImages = [...images];
      if (shouldMove) {
        newImages = newImages.filter((item) => !item.selected);
      } else {
        newImages = newImages.map((item) => {
          if (item.selected) {
            item.tag = tag;
            item.selected = false;
          }
          return item;
        });
      }
      setImages(newImages);
      notification.open({
        message: 'Your Change was Successful',
      });
    });
  };

  const onTrash = () => {
    const ids: any = [];

    images.map((e) => {
      if (e.selected) {
        ids.push(e.id);
      }
      return null;
    });

    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
    } else {
      setTrashCounter(3);
    }
  };

  const httpTrash = () => {
    setTrashCounter(0);
    const ids: any = [];

    images.map((e) => {
      if (e.selected) {
        ids.push(e.id);
      }
      return null;
    });

    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
    } else {
      const params = {
        ids,
      };

      Api.IMAGE_TRASH(params).then((() => {
        props.reloadPage();
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  };

  const httpEdit = (ids, field, value, callback) => {
    const params = {
      ids,
      field,
      value,
    };

    Api.IMAGE_EDIT(params).then((() => {
      callback();
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const http_load_categories = () => {
    Api.DETECTION_THRESHOLD().then(((res: any) => {
      const data = JSON.parse(res.text);
      const tcategories = data.filter((item) => item.enable == '1');
      const cates = tcategories.map((item) => item.category);
      cates.push('Other');
      setCategories(cates);
      if (cates.includes('Deer')) {
        const eCates = cates.filter((item) => item != 'Deer');
        eCates.unshift('Deer (Antlered)', 'Deer (Non antlered)');
        setEditCategories(eCates);
      } else {
        setEditCategories(cates);
      }
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onSendHD = () => {
    setOpenHD(false);
    const ids: any = [];
    images.map((image) => {
      if (image.selected) {
        ids.push(image.id);
      }
      return null;
    });

    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
      return;
    }

    const params = {
      ids,
      camera_id: filterOptions.camera_id,
    };
    setLoading(true);
    Api.IMAGE_REQUEST_HD_ONE_CAMERA(params).then(((res: any) => {
      setLoading(false);
      try {
        const result = JSON.parse(res.text);
        notification.open({
          message: result.message,
        });
      } catch (e) {
        notification.open({
          message: res.text,
        });
      }
    })).catch((error: any) => {
      console.log('error ===>', error);
      setLoading(false);
    });
  };

  return (
    <div className={classes.wrapper}>
      <ThemeProvider theme={outerTheme}>
        <Form form={form} style={{ marginTop: md ? 20 : 0 }} onValuesChange={onValuesChange}>
          <Form.Item name="camera_id" className={classes.formInputWrapper} initialValue="0">
            <Select
              onSelect={onCameraSelect}
              dropdownStyle={{ position: 'fixed' }}
              options={cameraOptions}
            />
          </Form.Item>
          <div style={{ display: filterOptions.camera_id == '0' || filterOptions.camera_id == '' ? 'none' : '' }}>
            <Form.Item label="Sort" name="order_by" style={{ marginBottom: 8 }} className={classes.formInputWrapper} initialValue="time">
              <Select dropdownStyle={{ position: 'fixed' }}>
                <Option key="id" value="id">Quick Sort</Option>
                <Option key="time" value="time">Taken Time</Option>
              </Select>
            </Form.Item>
            <Button type={selectAll ? 'default' : 'primary'} onClick={onClickSelectAll} style={{ marginTop: 8, width: '100%' }}>
              {selectAll ? 'Deselect All' : 'Select All'}
            </Button>
            <Divider style={{ marginBottom: 8 }}>Filter</Divider>
            <Button type={filterOptions.xl_only ? 'primary' : 'default'} className={classes.editTrash} onClick={onChangeXlOnly} icon={<img src="/icons/size-xl.png" alt="" className={classes.xl_img} />}>
              XL Images Only
            </Button>
            <Button type={filterOptions.video_only ? 'primary' : 'default'} className={classes.editTrash} onClick={onChangeVideoOnly} icon={<PlayCircleOutlined />}>
              Videos Only
            </Button>
            <Button type={filterOptions.favorite_only ? 'primary' : 'default'} className={classes.editTrash} onClick={onChangeFavoriteOnly} icon={<StarOutlined />}>
              Favorites Only
            </Button>
            {(filterOptions.category == 'Deer') && (
              <Radio.Group value={filterOptions.antlered} className={classes.categoryGroup} onChange={changeDeerSubCategroy}>
                <Row>
                  <Col span={12}><Radio.Button className={classes.categoryBtn} value="0">All Deer</Radio.Button></Col>
                  <Col span={12}><Radio.Button className={classes.categoryBtn} value="1">Bucks Only</Radio.Button></Col>
                </Row>
              </Radio.Group>
            )}
            <Form.Item name="category" className={classes.formInputWrapper} initialValue="All">
              <Select dropdownStyle={{ position: 'fixed' }}>
                <Option key="category0" value="All">All Categories</Option>
                {categories.map((val, i) => (
                  <Option key={`category${i + 1}`} value={val}>{val}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="tag" className={classes.formInputWrapper} initialValue="">
              <Select dropdownStyle={{ position: 'fixed' }}>
                <Option key="tag0" value="">All Tags</Option>
                {tags.map((val, i) => (
                  <Option key={`tag${i + 1}`} value={val}>{val}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item className={classes.formInputWrapper}>
              <div>
                <RangePicker style={{ width: '100%' }} onChange={onChangeDate} />
              </div>
            </Form.Item>
          </div>
        </Form>
        <div style={{ display: filterOptions.camera_id == '0' || filterOptions.camera_id == '' ? 'none' : '' }}>
          <Divider style={{ marginBottom: 8 }}>Edit</Divider>
          <Spin spinning={loading} size="large">
            <Form form={formEdit} style={{ marginTop: md ? 5 : 0 }}>
              <Form.Item name="to_category" className={classes.formInputWrapper}>
                <Select placeholder="Change Category To:" onChange={onChangeCategory} dropdownStyle={{ position: 'fixed' }} disabled={!selectedEditOptions.able_category_change}>
                  {editCategories.map((val, i) => (
                    <Option key={i} value={val}>{val}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="to_tag" className={classes.formInputWrapper}>
                <Select placeholder="Change Tag To:" onChange={onChangeTag} dropdownStyle={{ position: 'fixed' }} disabled={!selectedEditOptions.able_tag}>
                  <Option key="tag0" value="">Clear</Option>
                  {tags.map((val, i) => (
                    <Option key={`tag${i + 1}`} value={val}>{val}</Option>
                  ))}
                </Select>
              </Form.Item>
              {trashCounter > 0 && (
                <Button type="primary" onClick={httpTrash} className={classes.editTrash} danger>
                  Confirm ({trashCounter})
                </Button>
              )}
              {trashCounter == 0 && (
                <Button type="primary" onClick={onTrash} className={classes.editTrash} disabled={!selectedEditOptions.able_delete}>
                  Trash
                </Button>
              )}
              <div>
                <Button type={filterOptions.video_only ? 'primary' : 'default'} className={classes.editTrash} onClick={() => { setOpenHD(true); }} icon={<SendOutlined />} disabled={!selectedEditOptions.able_request_hd}>
                  Request Video/XL Image
                </Button>
                <Modal
                  title="Do you wish to continue?"
                  visible={openHD}
                  onOk={onSendHD}
                  onCancel={() => { setOpenHD(false); }}
                >
                  <p>There is no additional cost to requesting XL versions of your images or full length videos. However, the larger pictures and longer videos will consume more of your monthly data allotment than the normal pictures. Please monitor your data usage page and contact support with any questions.</p>
                </Modal>
              </div>
            </Form>
          </Spin>
        </div>
      </ThemeProvider>
    </div>
  );
};
export default ShareImageFilter;
