import queryString from 'query-string';
import { postRequest, getRequest, deleteRequest } from './api-requests';

import config from './config';

export const AUTH_LOGIN = (queryObj: any) => postRequest(`${config.baseURL}/auth/login_json`, queryObj);
export const AUTH_LOGIN_TOKEN = (queryObj: any) => postRequest(`${config.baseURL}/auth/login?${queryString.stringify(queryObj)}`, {});
export const AUTH_REGISTER = (queryObj: any) => postRequest(`${config.baseURL}/auth/register?${queryString.stringify(queryObj)}`, {});
export const AUTH_PASSWORD_REQUEST = (queryObj: any) => postRequest(`${config.baseURL}/auth/password_reset_request?${queryString.stringify(queryObj)}`, {});
export const AUTH_PASSWORD_CODE = (queryObj: any) => postRequest(`${config.baseURL}/auth/password_validate_code?${queryString.stringify(queryObj)}`, {});
export const AUTH_PASSWORD_SET = (queryObj: any) => postRequest(`${config.baseURL}/auth/password_set?${queryString.stringify(queryObj)}`, {});
export const AUTH_GET_TEMPUSER = (queryObj: any) => postRequest(`${config.baseURL}/auth/get_tempuser?${queryString.stringify(queryObj)}`, {});
export const AUTH_REGISTER_CAMERA = (queryObj: any) => postRequest(`${config.baseURL}/auth/register_camera?${queryString.stringify(queryObj)}`, {});

export const AUTH_GET_STATES = (queryObj: any) => getRequest(`${config.baseURL}/auth/states?${queryString.stringify(queryObj)}`);

export const STRIPE_SUBSCRIBE_SIGNUP = (queryObj: any) => postRequest(`${config.baseURL}/stripe/subscribe_signup?${queryString.stringify(queryObj)}`, {});
export const STRIPE_GET_PRICES = () => postRequest(`${config.baseURL}/stripe/getPrices?`, {});
export const STRIPE_CHECK_PROMOTION = (queryObj: any) => postRequest(`${config.baseURL}/stripe/getCoupon?${queryString.stringify(queryObj)}`, {});

export const getProfile = () => getRequest(`${config.baseURL}/profile`);

export const getCameras = (queryObj: any) => postRequest(`${config.baseURL}/camera/getCameras?${queryString.stringify(queryObj)}`, {});
export const getCamerasAndGroups = (queryObj: any) => postRequest(`${config.baseURL}/camera/getCamerasAndGroups?${queryString.stringify(queryObj)}`, {});

export const CHART_BESTTIME = (queryObj: any) => postRequest(`${config.baseURL}/activity/besttime_24`, queryObj);
export const CHART_WEATHER = (queryObj: any) => postRequest(`${config.baseURL}/activity/chart_weather`, queryObj);
export const CHART_DATE = (queryObj: any) => postRequest(`${config.baseURL}/activity/monthly_daily`, queryObj);
export const CHART_SUMMARY = (queryObj: any) => postRequest(`${config.baseURL}/activity/chart_summary`, queryObj);
export const CHART_WEATHER_FORECAST = (queryObj: any) => postRequest(`${config.baseURL}/chart/weather_forecast`, queryObj);

export const SMARTHUNT_DAILY = (queryObj: any) => getRequest(`${config.baseURL}/smartHunt/chart_date?${queryString.stringify(queryObj)}`);
export const SMARTHUNT_TIME = (queryObj: any) => getRequest(`${config.baseURL}/smartHunt/besttime?${queryString.stringify(queryObj)}`);
export const SMARTHUNT_PREDICT = (queryObj: any) => getRequest(`${config.baseURL}/smartHunt/weather_forecast?${queryString.stringify(queryObj)}`);
export const SMARTHUNT_RECENTLY_IMAGES = (queryObj: any) => getRequest(`${config.baseURL}/smartHunt/recently_images?${queryString.stringify(queryObj)}`);

export const DASHBOARD_HEADER = () => getRequest(`${config.baseURL}/dashboard/header`);
export const DASHBOARD_CHART_CAMERA = (queryObj: any) => postRequest(`${config.baseURL}/dashboard/chart_camera`, queryObj);
export const DASHBOARD_CHART_BESTTIME = (queryObj: any) => postRequest(`${config.baseURL}/dashboard/chart_time`, queryObj);
export const DASHBOARD_CHART_MONTH = (queryObj: any) => postRequest(`${config.baseURL}/dashboard/chart_month`, queryObj);
export const DASHBOARD_RECENTLY_IMAGES = () => getRequest(`${config.baseURL}/dashboard/recently_images?`);

export const IMAGE_UPLOAD_URL = `${config.baseURL}/auth/upload_file`;
export const IMAGE_ALL_TAGS = (queryObj: any) => postRequest(`${config.baseURL}/image/getTags?${queryString.stringify(queryObj)}`, {});
export const IMAGE_EDIT = (queryObj: any) => postRequest(`${config.baseURL}/image/image_edit?`, queryObj);
export const IMAGE_EDIT_ONE = (queryObj: any) => postRequest(`${config.baseURL}/image/image_edit_one?`, queryObj);
// export const IMAGE_LOAD_ALL = (queryObj: any) => postRequest(`${config.baseURL}/image/gallery`, queryObj);
// export const IMAGE_LOAD_ALL = (queryObj: any) => postRequest(`${config.baseURL}/image/gallery_v2`, queryObj);
// export const IMAGE_LOAD_ALL = (queryObj: any) => postRequest(`${config.baseURL}/image/gallery_v3`, queryObj);
export const IMAGE_LOAD_ALL = (queryObj: any) => postRequest(`${config.baseURL}/image/gallery_v4`, queryObj);
export const IMAGE_DETAIL = (image_id: string) => getRequest(`${config.baseURL}/image/${image_id}`);
export const IMAGE_FAVORITE_CHANGE = (queryObj: any) => postRequest(`${config.baseURL}/image/favorite`, queryObj);
export const IMAGE_REQUEST_COUNTING = (queryObj: any) => postRequest(`${config.baseURL}/image/get_categories`, queryObj);
export const IMAGE_REQUEST_MODIFY_COUNTING = (queryObj: any) => postRequest(`${config.baseURL}/image/modify_category_count`, queryObj);

export const IMAGE_TRASH = (queryObj: any) => postRequest(`${config.baseURL}/imageTrash/trash?`, queryObj);
export const IMAGE_LOAD_TRASH = (queryObj: any) => getRequest(`${config.baseURL}/imageTrash/loadData?${queryString.stringify(queryObj)}`);
export const IMAGE_RESTORE = (queryObj: any) => postRequest(`${config.baseURL}/imageTrash/restore?`, queryObj);
export const IMAGE_DELETE = (queryObj: any) => postRequest(`${config.baseURL}/imageTrash/delete?`, queryObj);
export const IMAGE_REQUEST_HD_ONE_CAMERA = (queryObj: any) => postRequest(`${config.baseURL}/remoteControl/request_HD_one_camera?`, queryObj);

export const MOVE_TRASH_WITH_OPTIONS = (queryObj: any) => postRequest(`${config.baseURL}/imageTrash/move_trash_options?`, queryObj);
export const MOVE_TRASH_WITH_OPTIONS_COUNT = (queryObj: any) => postRequest(`${config.baseURL}/imageTrash/move_trash_options_count?`, queryObj);
export const AUTO_TRASH_OPTIONS = (queryObj: any) => postRequest(`${config.baseURL}/imageTrash/auto_trash_options?`, queryObj);

export const IMAGE_TAG_ASSIGN_ALL = () => getRequest(`${config.baseURL}/tagAssign`);
export const IMAGE_TAG_ASSIGN_UPDATE = (queryObj: any) => postRequest(`${config.baseURL}/tagAssign`, queryObj);
export const IMAGE_TAG_ASSIGN_ADD = (queryObj: any) => postRequest(`${config.baseURL}/tagAssign/add_tag?`, queryObj);
export const TAG_DETAIL_ALL = () => getRequest(`${config.baseURL}/tag/detail_all`);
export const TAG_DELETE = (queryObj: any) => postRequest(`${config.baseURL}/tag/delete_tag?`, queryObj);
export const TAG_CLEAR = (queryObj: any) => postRequest(`${config.baseURL}/tag/clear_tag?`, queryObj);
export const TAG_ASSIGN_UPDATE = (queryObj: any) => postRequest(`${config.baseURL}/tag/tag_assign_update?`, queryObj);
export const TAG_UPDATE = (queryObj: any) => postRequest(`${config.baseURL}/tag/tag_update?`, queryObj);

export const CAMERA_GET_ALL = () => getRequest(`${config.baseURL}/camera`);
export const CAMERA_GET_ONE = (camera_id: string) => getRequest(`${config.baseURL}/camera/${camera_id}`);
export const CAMERA_CREATE_SMART = (queryObj: any) => postRequest(`${config.baseURL}/camera/create_smart?${queryString.stringify(queryObj)}`, {});
export const CAMERA_CREATE_GENERAL = (queryObj: any) => postRequest(`${config.baseURL}/camera/create_general?${queryString.stringify(queryObj)}`, {});
export const CAMERA_CREATE = (queryObj: any) => postRequest(`${config.baseURL}/camera/create`, queryObj);
export const CAMERA_DELETE = (camera_id: string) => getRequest(`${config.baseURL}/camera/delete/${camera_id}`);
export const CAMERA_ACTIVATE = (queryObj: any) => postRequest(`${config.baseURL}/camera/activate`, queryObj);
export const CAMERA_EDIT = (queryObj: any) => postRequest(`${config.baseURL}/camera/edit?${queryString.stringify(queryObj)}`, {});
export const CAMERA_ASSIGN = (queryObj: any) => postRequest(`${config.baseURL}/camera/assign`, queryObj);
export const CAMERA_ARCHIVE = (queryObj: any) => postRequest(`${config.baseURL}/camera/archive`, queryObj);
export const CAMERAS_GROUPS = () => getRequest(`${config.baseURL}/camera/cameras_groups`);
export const CAMERAS_GROUPS_TAGS = () => getRequest(`${config.baseURL}/camera/cameras_groups_tags`);
export const CAMERA_RECENTLY_IMAGES = (camera_id: string) => getRequest(`${config.baseURL}/camera/recently_images/${camera_id}`);
export const CAMERA_TRASH_ALL = (queryObj: any) => postRequest(`${config.baseURL}/imageTrash/trash_camera_all?`, queryObj);

export const CAMERA_GET_LIVE_ALL = () => getRequest(`${config.baseURL}/camera/livecamera_all`);
export const CAMERA_GET_LIVE_INFO = (queryObj: any) => getRequest(`${config.baseURL}/camera/livecamera?${queryString.stringify(queryObj)}`);
export const CAMERA_NOTIFICATION_EMAIL = (queryObj: any) => postRequest(`${config.baseURL}/camera/notification_email`, queryObj);
export const CAMERA_NOTIFICATION_PUSH = (queryObj: any) => postRequest(`${config.baseURL}/camera/notification_push`, queryObj);
export const CAMERA_DATA_SAVER = (queryObj: any) => postRequest(`${config.baseURL}/camera/data_saver`, queryObj);

export const REMOTE_CONTROL_STANDARD = (queryObj: any) => postRequest(`${config.baseURL}/remoteControl/livecamera_standard`, queryObj);
export const REMOTE_CONTROL_GETAPICKTURE_BIG = (queryObj: any) => postRequest(`${config.baseURL}/remoteControl/getapicture_big`, queryObj);
export const REMOTE_CONTROL_MINI = (queryObj: any) => postRequest(`${config.baseURL}/remoteControl/livecamera_mini`, queryObj);
export const REMOTE_CONTROL_MINI_D404 = (queryObj: any) => postRequest(`${config.baseURL}/remoteControl/livecamera_mini_d404`, queryObj);
export const REMOTE_CONTROL_GETAPICKTURE_MINI = (queryObj: any) => postRequest(`${config.baseURL}/remoteControl/getapicture_mini`, queryObj);
export const REMOTE_CONTROL_RESET_MINI = (queryObj: any) => postRequest(`${config.baseURL}/remoteControl/reset_mini`, queryObj);
export const REMOTE_CONTROL_FIRMWARE_UPDATE_MINI = (queryObj: any) => postRequest(`${config.baseURL}/remoteControl/firmware_update_mini`, queryObj);
export const REMOTE_CONTROL_DEVICE_SETTING = (arg: string) => getRequest(`${config.baseURL}/camera/device_setting/${arg}`);

export const DC2_DEVICE_SETTING = (arg: string) => getRequest(`${config.baseURL}/remoteControlDC2/device_setting/${arg}`);
export const DC2_SEND_SETTINGS = (queryObj: any) => postRequest(`${config.baseURL}/remoteControlDC2/send_settings`, queryObj);
export const DC2_FIRMWARE_UPDATE = (queryObj: any) => postRequest(`${config.baseURL}/remoteControlDC2/firmware_update`, queryObj);
export const DC2_RESET = (queryObj: any) => postRequest(`${config.baseURL}/remoteControlDC2/reset`, queryObj);
export const DC2_GETAPICKTURE = (queryObj: any) => postRequest(`${config.baseURL}/remoteControlDC2/getapicture`, queryObj);
export const DC2_PLAY_VIDEO = (queryObj: any) => postRequest(`${config.baseURL}/remoteControlDC2/playvideo`, queryObj);
export const DC2_FORMAT = (queryObj: any) => postRequest(`${config.baseURL}/remoteControlDC2/format`, queryObj);
export const DC2_PASSWORD = (queryObj: any) => postRequest(`${config.baseURL}/remoteControlDC2/password`, queryObj);
export const DC2_GET_GPS = (queryObj: any) => postRequest(`${config.baseURL}/remoteControlDC2/get_gps`, queryObj);

export const MC2_SETTING_GET = (arg: String) => getRequest(`${config.baseURL}/remoteControlMC2/setting/${arg}`);
export const MC2_SETTING_UPDATE = (queryObj: any) => postRequest(`${config.baseURL}/remoteControlMC2/setting`, queryObj);
export const MC2_FIRMWARE_UPDATE = (queryObj: any) => postRequest(`${config.baseURL}/remoteControlMC2/firmware_update`, queryObj);
export const MC2_SEND_COMMAND = (queryObj: any) => postRequest(`${config.baseURL}/remoteControlMC2/sendcommand`, queryObj);

export const DC2B_SETTING_GET = (arg: String) => getRequest(`${config.baseURL}/remoteControlDC2B/setting/${arg}`);
export const DC2B_SETTING_UPDATE = (queryObj: any) => postRequest(`${config.baseURL}/remoteControlDC2B/setting`, queryObj);
export const DC2B_FIRMWARE_UPDATE = (queryObj: any) => postRequest(`${config.baseURL}/remoteControlDC2B/firmware_update`, queryObj);
export const DC2B_SEND_COMMAND = (queryObj: any) => postRequest(`${config.baseURL}/remoteControlDC2B/sendcommand`, queryObj);

export const CAMERA_GROUP_CREATE = (queryObj: any) => postRequest(`${config.baseURL}/cameragroup/create`, queryObj);
export const CAMERA_GROUP_UPDATE = (queryObj: any) => postRequest(`${config.baseURL}/cameragroup/update`, queryObj);
export const CAMERA_GROUP_DELETE = (queryObj: any) => postRequest(`${config.baseURL}/cameragroup/delete`, queryObj);
export const CAMERA_GROUP_GET_ALL = () => getRequest(`${config.baseURL}/cameragroup`);
export const CAMERA_GROUP_GET = (queryObj: any) => postRequest(`${config.baseURL}/cameragroup/getOne`, queryObj);

export const MAP_CAMERAS_PINS = () => getRequest(`${config.baseURL}/map/cameras_pins`);
export const MAP_CAMERAS_PINS_DETAIL = () => getRequest(`${config.baseURL}/map/cameras_pins_detail`);
export const MAP_CREATE_PIN = (queryObj: any) => postRequest(`${config.baseURL}/map/pin_create?${queryString.stringify(queryObj)}`, {});
export const MAP_GET_PIN = (arg: string) => getRequest(`${config.baseURL}/map/pins/${arg}`);
export const MAP_PIN_EDIT = (queryObj: any) => postRequest(`${config.baseURL}/map/pin_update?${queryString.stringify(queryObj)}`, {});
export const MAP_PIN_DELETE = (id: string) => postRequest(`${config.baseURL}/map/pin_delete/${id}`, {});
export const MAP_GET_TIMEZONES = () => getRequest(`${config.baseURL}/map/list_timezones`);
export const MAP_REFRESH_TIMEZONE = (queryObj: any) => postRequest(`${config.baseURL}/map/fetch_timezone`, queryObj);

export const PROFILE_GET_USER = () => getRequest(`${config.baseURL}/profile`);
export const PROFILE_SET_USER = (queryObj: any) => postRequest(`${config.baseURL}/profile?${queryString.stringify(queryObj)}`, {});
export const PROFILE_REPORT_DURATION = (queryObj: any) => postRequest(`${config.baseURL}/profile/setReportDuration?${queryString.stringify(queryObj)}`, {});
export const PROFILE_REPORT_EMAIL_CATEGORIES = (queryObj: any) => postRequest(`${config.baseURL}/profile/setEmailFTP?${queryString.stringify(queryObj)}`, {});
export const PROFILE_REPORT_PUSH_CATEGORIES = (queryObj: any) => postRequest(`${config.baseURL}/profile/setPushFTP?${queryString.stringify(queryObj)}`, {});
export const PROFILE_GET_ADDITIONAL_EMAILS = () => getRequest(`${config.baseURL}/profile/additional_emails`);
export const PROFILE_ADDITIONAL_VERIFY = (queryObj: any) => postRequest(`${config.baseURL}/profile/request_email_verify?${queryString.stringify(queryObj)}`, {});
export const PROFILE_ADDITIONAL_DELETE = (queryObj: any) => postRequest(`${config.baseURL}/profile/remove_additional_email?${queryString.stringify(queryObj)}`, {});
export const PROFILE_SUBSCRIBE_UPDATE = (queryObj: any) => postRequest(`${config.baseURL}/profile/subscribe_update?${queryString.stringify(queryObj)}`, {});

export const PROFILE_WITH_SMARTCAMS = () => getRequest(`${config.baseURL}/profile/account_with_smartcams`);
export const PROFILE_GET_WP_TOKEN = () => getRequest(`${config.baseURL}/profile/wp_token`);

export const PROFILE_UPDATE_CAMERAS = (queryObj: any) => postRequest(`${config.baseURL}/profile/update_cameras?${queryString.stringify(queryObj)}`, {});
export const PROFILE_SUBSCRIBE_UPDATE_EXIST = (queryObj: any) => postRequest(`${config.baseURL}/profile/subscribe_update_exist?${queryString.stringify(queryObj)}`, {});
export const PROFILE_SUBSCRIBE_UPDATE_PRORATED = (queryObj: any) => postRequest(`${config.baseURL}/profile/subscribe_update_prorated?${queryString.stringify(queryObj)}`, {});
export const PROFILE_SUBSCRIBE_CANCEL = () => postRequest(`${config.baseURL}/profile/subscribe_cancel`, {});
export const PROFILE_SUBSCRIBE_PAUSE = () => getRequest(`${config.baseURL}/profile/subscribe_pause`);
export const ACCOUNT_MANAGEMENT_ADD_SMART = (queryObj: any) => postRequest(`${config.baseURL}/profile/create_smart?${queryString.stringify(queryObj)}`, {});
export const PROFILE_SUBSCRIBE_CANCELED = (queryObj: any) => postRequest(`${config.baseURL}/profile/subscribe_canceled?${queryString.stringify(queryObj)}`, {});
export const PROFILE_CHECKOUT_SESSION = () => getRequest(`${config.baseURL}/profile/stripe_checkout_session`);
export const PROFILE_DEFAULT_PAYMENT = (queryObj: any) => postRequest(`${config.baseURL}/profile/stripe_default_payment?${queryString.stringify(queryObj)}`, {});
export const STRIPE_SESSION_RETRIEVE = (queryObj: any) => getRequest(`${config.baseURL}/profile/stripe_session_retrieve?${queryString.stringify(queryObj)}`);
export const STRIPE_GET_CUSTOMER = () => getRequest(`${config.baseURL}/profile/stripe_customer`);

export const FORECAST_OPENWEATHER = (queryObj: any) => postRequest(`${config.baseURL}/forecast/openweather`, queryObj);
export const FORECAST_OPENWEATHER_PREDICT = (queryObj: any) => postRequest(`${config.baseURL}/forecast/predict_openweather`, queryObj);
export const FORECAST_WEATHER = (queryObj: any) => postRequest(`${config.baseURL}/forecast/weather`, queryObj);
export const FORECAST_PREDICT = (queryObj: any) => postRequest(`${config.baseURL}/forecast/predict`, queryObj);

export const SUB_CAMERA_ALL = () => getRequest(`${config.baseURL}/sub/cameras`);
export const SUB_IMAGE_ALL = (queryObj: any) => getRequest(`${config.baseURL}/sub/images?${queryString.stringify(queryObj)}`);
export const SUB_ACTIVITY = (queryObj: any) => getRequest(`${config.baseURL}/sub/activity?${queryString.stringify(queryObj)}`);
export const SUB_USERS_CAMERAS_TAGS_ALL = () => getRequest(`${config.baseURL}/sub/users_cameras_tags`);

export const STRIPE_CUSTOMER_PORTAL = `${config.baseURL}/stripe/viewCustomerPortal/`;

export const DATA_USAGE_SUBSCRIPTION = () => getRequest(`${config.baseURL}/datausage/my_subscription`);
export const DATA_USAGE_CURRENT = () => getRequest(`${config.baseURL}/datausage/current_usage`);
export const DATA_USAGE_FORECAST = () => getRequest(`${config.baseURL}/datausage/current_forecast`);
export const DATA_USAGE_TOP_CAMERAS = () => getRequest(`${config.baseURL}/datausage/top5_cameras`);

export const DETECTION_THRESHOLD = () => getRequest(`${config.baseURL}/threshold`);
export const DETECTION_THRESHOLD_UPDATE = (queryObj: any) => postRequest(`${config.baseURL}/threshold`, queryObj);

export const SHARE_USER_LIST = () => getRequest(`${config.baseURL}/share/user_list`);
export const SHRAE_USER_ADD = (queryObj: any) => postRequest(`${config.baseURL}/share/user_add`, queryObj);
export const SHRAE_USER_EDIT = (queryObj: any) => postRequest(`${config.baseURL}/share/user_edit`, queryObj);
export const SHRAE_USER_DELETE = (queryObj: any) => postRequest(`${config.baseURL}/share/user_delete`, queryObj);
export const SHRAED_CAMERA = () => getRequest(`${config.baseURL}/share/shared_cameras`);
export const SHRAED_CAMERA_TAG = () => getRequest(`${config.baseURL}/share/shared_cameras_tags`);
export const SHRAED_IMAGES = (queryObj: any) => postRequest(`${config.baseURL}/share/shared_images`, queryObj);
